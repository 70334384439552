(() => {
  var gt = Object.defineProperty,
    wt = Object.defineProperties;
  var yt = Object.getOwnPropertyDescriptors;
  var H = Object.getOwnPropertySymbols;
  var bt = Object.prototype.hasOwnProperty,
    Ct = Object.prototype.propertyIsEnumerable;
  var M = (t, e, o) =>
      e in t
        ? gt(t, e, {
            enumerable: !0,
            configurable: !0,
            writable: !0,
            value: o,
          })
        : (t[e] = o),
    j = (t, e) => {
      for (var o in e || (e = {})) bt.call(e, o) && M(t, o, e[o]);
      if (H) for (var o of H(e)) Ct.call(e, o) && M(t, o, e[o]);
      return t;
    },
    W = (t, e) => wt(t, yt(e));
  var q = (t, e, o) => (M(t, typeof e != "symbol" ? e + "" : e, o), o);
  var p = (t, e, o) =>
    new Promise((r, n) => {
      var s = (a) => {
          try {
            l(o.next(a));
          } catch (c) {
            n(c);
          }
        },
        i = (a) => {
          try {
            l(o.throw(a));
          } catch (c) {
            n(c);
          }
        },
        l = (a) => (a.done ? r(a.value) : Promise.resolve(a.value).then(s, i));
      l((o = o.apply(t, e)).next());
    });
  var f = class {
    static activateAlerts() {
      this.alertsActivated = !0;
    }
    static alert(e, o) {
      if ((this.alertsActivated && window.alert(e), o === "error"))
        throw new Error(e);
    }
  };
  q(f, "alertsActivated", !1);
  var k = "w--current";
  var I = {
    dropdown: "w-dropdown",
    dropdownToggle: "w-dropdown-toggle",
    dropdownList: "w-dropdown-list",
  };
  var h = (t, e) => (
    Array.isArray(e) || (e = [e]),
    e
      .map((r) =>
        t.dispatchEvent(
          new Event(r, {
            bubbles: !0,
          })
        )
      )
      .every((r) => r)
  );
  var N = (t, e) => !!t && e.includes(t);
  var x = (t) => (e) => `${t}${e ? `-${e}` : ""}`,
    D = (t) => {
      let e = (r, n, s) => {
        let i = t[r],
          { key: l, values: a } = i,
          c;
        if (!n) return `[${l}]`;
        let m = a == null ? void 0 : a[n];
        if (
          (typeof m == "string"
            ? (c = m)
            : (c = m(s && "instanceIndex" in s ? s.instanceIndex : void 0)),
          !(s == null ? void 0 : s.operator))
        )
          return `[${l}="${c}"]`;
        switch (s.operator) {
          case "prefixed":
            return `[${l}^="${c}"]`;
          case "suffixed":
            return `[${l}$="${c}"]`;
          case "contains":
            return `[${l}*="${c}"]`;
        }
      };
      return [
        e,
        (r, n) => {
          let s = e("element", r, n);
          return ((n == null ? void 0 : n.scope) || document).querySelector(s);
        },
      ];
    };
  var $ = "fs-attributes",
    L = {
      preventLoad: {
        key: `${$}-preventload`,
      },
      debugMode: {
        key: `${$}-debug`,
      },
      src: {
        key: "src",
        values: {
          finsweet: "@finsweet/attributes",
        },
      },
      dev: {
        key: `${$}-dev`,
      },
    },
    [B, be] = D(L);
  var G = () => {
    let { currentScript: t } = document,
      { preventLoad: e, debugMode: o } = L,
      r = typeof (t == null ? void 0 : t.getAttribute(e.key)) == "string";
    return (
      typeof (t == null ? void 0 : t.getAttribute(o.key)) == "string" &&
        f.activateAlerts(),
      {
        preventsLoad: r,
      }
    );
  };
  var X = () => {
      if (window.fsAttributes && !Array.isArray(window.fsAttributes)) return;
      let t = {
        cms: {},
        push(...e) {
          var o, r;
          for (let [n, s] of e)
            (r = (o = this[n]) == null ? void 0 : o.loading) == null ||
              r.then(s);
        },
      };
      xt(t),
        Lt(t),
        (window.fsAttributes = t),
        (window.FsAttributes = window.fsAttributes);
    },
    xt = (t) => {
      let e = B("src", "finsweet", {
          operator: "contains",
        }),
        o = B("dev"),
        n = [...document.querySelectorAll(`script${e}, script${o}`)].reduce(
          (s, i) => {
            var a;
            let l =
              i.getAttribute(L.dev.key) ||
              ((a = i.src.match(/[\w-. ]+(?=(\.js)$)/)) == null
                ? void 0
                : a[0]);
            return l && !s.includes(l) && s.push(l), s;
          },
          []
        );
      for (let s of n) {
        t[s] = {};
        let i = t[s];
        i.loading = new Promise((l) => {
          i.resolve = (a) => {
            l(a), delete i.resolve;
          };
        });
      }
    },
    Lt = (t) => {
      let e = Array.isArray(window.fsAttributes) ? window.fsAttributes : [];
      t.push(...e);
    };
  var Tt =
      "https://cdn.jsdelivr.net/npm/@finsweet/attributes-animation@1/functions.js",
    v = () =>
      p(void 0, null, function* () {
        let { fsAttributes: t } = window;
        if (t.animationImport) return t.animationImport;
        try {
          let e = import(Tt);
          return (t.animationImport = e), e;
        } catch (e) {
          f.alert(`${e}`, "error");
          return;
        }
      });
  var _t =
      "https://cdn.jsdelivr.net/npm/@finsweet/attributes-cmscore@1/cmscore.js",
    R = () =>
      p(void 0, null, function* () {
        let { fsAttributes: t } = window;
        t.cms || (t.cms = {});
        let { cms: e } = t;
        if (e.coreImport) return e.coreImport;
        try {
          let o = import(_t);
          return (
            (e.coreImport = o),
            o.then((r) => {
              r && (e.coreVersion || (e.coreVersion = r.version));
            }),
            o
          );
        } catch (o) {
          f.alert(`${o}`, "error");
          return;
        }
      });
  var z = "1.7.0";
  var J = 0.1;
  var Q = (r, n) =>
    p(void 0, [r, n], function* (t, { durationKey: e, easingKey: o }) {
      let s = yield v();
      if (!s) return;
      let {
          animations: { fade: i },
          easings: l,
        } = s,
        { listAnimation: a } = t,
        c = t.getAttribute(e),
        m = t.getAttribute(o);
      if (a && !c && !m) return;
      let u = N(m, l) ? m : void 0,
        S = c ? parseFloat(c) / 2e3 : J;
      if (!a) {
        t.listAnimation = W(j({}, i), {
          options: {
            easing: u,
            duration: S,
          },
        });
        return;
      }
      let { options: d } = a;
      if (!d) {
        a.options = {
          easing: u,
          duration: S,
        };
        return;
      }
      d.easing || (d.easing = u), c && (d.duration = S);
    });
  var w = "cmssort",
    A = `fs-${w}`,
    Dt = "list",
    vt = "trigger",
    Rt = "dropdown-label",
    Kt = "scroll-anchor",
    Ot = "field",
    Mt = "type",
    kt = "easing",
    ht = "duration",
    Nt = "asc",
    $t = "desc",
    Bt = "reverse",
    E = {
      element: {
        key: `${A}-element`,
        values: {
          list: x(Dt),
          trigger: x(vt),
          dropdownLabel: x(Rt),
          scrollAnchor: x(Kt),
        },
      },
      field: {
        key: `${A}-${Ot}`,
      },
      type: {
        key: `${A}-${Mt}`,
        values: {
          date: "date",
        },
      },
      easing: {
        key: `${A}-${kt}`,
      },
      duration: {
        key: `${A}-${ht}`,
      },
      ascClass: {
        key: `${A}-${Nt}`,
      },
      descClass: {
        key: `${A}-${$t}`,
      },
      reverse: {
        key: `${A}-${Bt}`,
        values: {
          true: "true",
        },
      },
    },
    [K, O] = D(E),
    Z = `${A}_asc`,
    tt = `${A}_desc`;
  var {
      field: { key: Ut },
      type: { key: Ft },
    } = E,
    et = (t, e) => {
      t.on("shouldcollectprops", (o) =>
        p(void 0, null, function* () {
          for (let r of o)
            r.collectProps({
              fieldKey: Ut,
              typeKey: Ft,
            });
        })
      ),
        t.on("shouldsort", () =>
          p(void 0, null, function* () {
            yield e(!0);
          })
        );
    };
  function y(t) {
    return t == null ? void 0 : t.trim().toLowerCase();
  }
  var T = "role",
    b = {
      slider: "slider",
      listbox: "listbox",
      option: "option",
      columnheader: "columnheader",
    },
    ot = "tabindex";
  var U = "aria-selected",
    rt = "aria-haspopup",
    st = "aria-multiselectable";
  var nt = "aria-sort",
    F = {
      ascending: "ascending",
      descending: "descending",
    };
  var C = (n, s) =>
    p(
      void 0,
      [n, s],
      function* (t, { direction: e, sortKey: o, addingItems: r }) {
        let { items: i } = t;
        e && o && i.some(({ props: a }) => o in a)
          ? i.sort((a, c) => {
              let m = a.props[o],
                u = c.props[o],
                [S] = (m == null ? void 0 : m.values) || [],
                [d] = (u == null ? void 0 : u.values) || [];
              // Custom Code
              if (d === "0") {
                return -1;
              }
              if (S === "0") {
                return 1;
              }
              if (!S) return -1;
              if (!d) return 1;
              let { type: _ } = m;
              if (_ === "date") {
                let Y = new Date(S).getTime(),
                  V = new Date(d).getTime();
                return e === "asc" ? Y - V : V - Y;
              }
              let g = {
                numeric: !0,
                sensitivity: "base",
              };
              return e === "asc"
                ? S.localeCompare(d, void 0, g)
                : d.localeCompare(S, void 0, g);
            })
          : t.restoreItemsOrder(),
          r ||
            (yield t.switchPage(1, !1),
            t.scrollToAnchor(),
            yield t.renderItems());
      }
    );
  var {
      ascClass: { key: Pt },
      descClass: { key: Yt },
      reverse: { key: Vt, values: Ht },
    } = E,
    it = (t, e, o) => {
      let r = new Map(),
        n = !1,
        s,
        i,
        l,
        a = (c) =>
          p(void 0, null, function* () {
            yield C(e, {
              sortKey: l,
              direction: i,
              addingItems: c,
            });
          });
      for (let c of t)
        jt(c, r, o),
          c.addEventListener("click", (m) =>
            p(void 0, null, function* () {
              if ((m.preventDefault(), n)) return;
              n = !0;
              let u = r.get(c);
              if (!u) {
                n = !1;
                return;
              }
              (l = u.sortKey),
                (i = Wt(u.direction, u.reverse)),
                s && c !== s && lt(s, void 0, r),
                (s = c),
                lt(c, i, r),
                yield a(),
                (n = !1);
            })
          );
      return a;
    },
    jt = (t, e, o) => {
      let r = t.getAttribute(E.field.key);
      if (!r) return;
      let n = y(r),
        s = t.getAttribute(Vt) === Ht.true,
        i = t.getAttribute(Pt),
        l = t.getAttribute(Yt),
        a = {
          sortKey: n,
          reverse: s,
          cssClasses: {
            asc: i || o.asc,
            desc: l || o.desc,
          },
        };
      t.setAttribute(T, b.columnheader),
        t.setAttribute(ot, "0"),
        ct(t),
        e.set(t, a),
        at(t, a);
    },
    at = (...[t, { cssClasses: e }]) => {
      for (let o of Object.values(e)) t.classList.remove(o);
    },
    ct = (t, e) => {
      t.setAttribute(
        nt,
        e ? (e === "asc" ? F.ascending : F.descending) : "none"
      );
    },
    lt = (t, e, o) => {
      let r = o.get(t);
      if (!r) return;
      let { cssClasses: n } = r;
      at(t, r), e && t.classList.add(n[e]), ct(t, e), (r.direction = e);
    },
    Wt = (t, e) => (t ? (t === "desc" ? "asc" : "desc") : e ? "desc" : "asc");
  var pt = (t, e = !0) => {
    e && t.focus(), h(t, ["click", "mouseup"]);
  };
  var { dropdownToggle: qt, dropdownList: Gt } = I,
    mt = (t, e) => {
      let o = t.querySelector(`.${qt}`),
        r = t.querySelector(`.${Gt}`);
      if (!o || !r) {
        f.alert("The cmssort Dropdown is missing a toggle or a list.", "error");
        return;
      }
      Jt(o, r);
      let n = zt(o),
        s = Xt(r);
      if (!s) {
        f.alert("The cmssort Dropdown doesn't have any option.", "error");
        return;
      }
      let i = !1,
        l,
        a,
        c = (m) =>
          p(void 0, null, function* () {
            yield C(e, {
              direction: a,
              sortKey: l,
              addingItems: m,
            });
          });
      return (
        r.addEventListener("click", (m) =>
          p(void 0, null, function* () {
            if ((m.preventDefault(), i)) return;
            i = !0;
            let { target: u } = m;
            if (!(u instanceof Element)) {
              i = !1;
              return;
            }
            let S = u.closest("a");
            if (!S) {
              i = !1;
              return;
            }
            let d = s.find(({ element: g }) => g === S);
            if (!d || d.selected) {
              i = !1;
              return;
            }
            let _ = s.find(({ selected: g }) => g);
            _ && (_.selected = !1),
              (d.selected = !0),
              ({ sortKey: l, direction: a } = d),
              Qt(s),
              n == null || n.updateContent(d),
              pt(o),
              yield c(),
              (i = !1);
          })
        ),
        c
      );
    },
    Xt = (t) => {
      let e = [],
        o = t.querySelectorAll("a");
      if (!!o.length) {
        for (let r of o) {
          r.setAttribute(T, b.option);
          let n = r.getAttribute(E.field.key),
            s,
            i;
          n &&
            (n.endsWith("-asc")
              ? ((i = "asc"), (s = n.slice(0, -4)))
              : n.endsWith("-desc")
              ? ((i = "desc"), (s = n.slice(0, -5)))
              : ((i = "asc"), (s = n))),
            s && (s = y(s)),
            e.push({
              element: r,
              sortKey: s,
              direction: i,
              selected: !1,
            });
        }
        return e;
      }
    },
    zt = (t) => {
      let e = O("dropdownLabel", {
        operator: "prefixed",
        scope: t,
      });
      if (!e) return;
      let o = e.innerHTML;
      return {
        element: e,
        originalHTML: o,
        updateContent: ({ element: n, sortKey: s }) => {
          e.innerHTML = s ? n.innerHTML : o;
        },
      };
    },
    Jt = (t, e) => {
      t.setAttribute(rt, b.listbox),
        e.setAttribute(T, b.listbox),
        e.setAttribute(st, "false");
    },
    Qt = (t) => {
      for (let { element: e, selected: o } of t) {
        if (o) {
          e.setAttribute(U, "true"), e.classList.add(k);
          continue;
        }
        e.removeAttribute(U), e.classList.remove(k);
      }
    };
  var dt = (t, e) =>
      p(void 0, null, function* () {
        let o = t.closest("form");
        o == null || o.addEventListener("submit", Zt);
        let [r, n] = ut(t.value),
          s = !1,
          i = (l) =>
            p(void 0, null, function* () {
              yield C(e, {
                direction: n,
                sortKey: r,
                addingItems: l,
              });
            });
        return (
          t.addEventListener("change", () =>
            p(void 0, null, function* () {
              s || ((s = !0), ([r, n] = ut(t.value)), yield i(), (s = !1));
            })
          ),
          r && (yield i()),
          i
        );
      }),
    Zt = (t) => (t.preventDefault(), t.stopImmediatePropagation(), !1),
    ut = (t) => {
      let e, o;
      return (
        t.endsWith("-asc")
          ? ((o = "asc"), (e = t.slice(0, -4)))
          : t.endsWith("-desc")
          ? ((o = "desc"), (e = t.slice(0, -5)))
          : ((o = "asc"), (e = t)),
        (e = y(e)),
        [e, o]
      );
    };
  var {
      element: { key: te },
      field: { key: ee },
      type: { key: oe },
      duration: { key: re },
      easing: { key: se },
      ascClass: { key: ne },
      descClass: { key: ie },
    } = E,
    ft = (t) =>
      p(void 0, null, function* () {
        let e = t.getInstanceIndex(te),
          o = document.querySelectorAll(
            K("element", "trigger", {
              instanceIndex: e,
            })
          );
        if (!o.length) return;
        let { items: r } = t;
        for (let c of r)
          c.collectProps({
            fieldKey: ee,
            typeKey: oe,
          });
        if (
          (Q(t, {
            durationKey: re,
            easingKey: se,
          }),
          !t.scrollAnchor)
        ) {
          let c = O("scrollAnchor", {
            instanceIndex: e,
          });
          c && (t.scrollAnchor = c);
        }
        let n = {
            asc: t.getAttribute(ne) || Z,
            desc: t.getAttribute(ie) || tt,
          },
          [s] = o,
          i = s instanceof HTMLSelectElement,
          l = s.closest(`.${I.dropdown}`),
          a = i ? yield dt(s, t) : l ? mt(l, t) : it(o, t, n);
        !a || et(t, a);
      });
  var P = () =>
    p(void 0, null, function* () {
      var o, r;
      let t = yield R();
      if (!t) return [];
      let e = t.createCMSListInstances([
        K("element", "list", {
          operator: "prefixed",
        }),
      ]);
      return (
        yield Promise.all(e.map(ft)),
        (r = (o = window.fsAttributes[w]).resolve) == null || r.call(o, e),
        e
      );
    });
  X();
  R();
  v();
  var At, Et;
  (At = window.fsAttributes)[(Et = w)] || (At[Et] = {});
  var { preventsLoad: ae } = G(),
    St = window.fsAttributes[w];
  St.version = z;
  ae
    ? (St.init = P)
    : (window.Webflow || (window.Webflow = []), window.Webflow.push(P));
})();
